import React from "react";

function ConstructorStandingsTable(props) {

    return(
                <tr>
                    <td>{props.position}</td>
                    <td style={{"minWidth": "14em"}}><div className={"flag-pic " + props.country}></div>{props.constructor}</td>
                    <td>{props.wins}</td>
                    <td>{props.points}</td>
                </tr>
    )
}

export default ConstructorStandingsTable;