import React from "react";
import { Link } from "react-router-dom";

function DriverStandingsTable(props) {

    return(
                <tr>
                    <td>{props.position}</td>
                    <td style={{"minWidth": "14em"}}><div className={"flag-pic " + props.country}></div>
                        {props.driverLink ? <Link to={{
                                                pathname: "/drivers",
                                                param: {
                                                    pDriver: props.driverLink
                                                }
                                            }}>{props.driver}</Link> : props.driver}</td>
                    <td>{props.wins}</td>
                    <td>{props.points}</td>
                </tr>
    )
}

export default DriverStandingsTable;