import React from "react";
import RaceScheduleTable from "../Tables/RaceScheduleTable";
import DriverStandingsTable from "../Tables/DriverStandingsTable";
import ConstructorStandingsTable from "../Tables/ConstructorStandingsTable";
import nFetch from "node-fetch";
import CountryCodes from "../../country_codes.json";
import fetch_url_prefix from "./env_url";
import getResultableRacesList from "./getResultableRacesList";

let year = null;
let currentSeason = null;
let racesDataList = null;
let promise1 = null;
let driversDataList = null;
let promise2 = null;
let constructorsDataList = null;
let promise3 = null;
let promiseAll = null;
let resultableRacesList = [];
let param = {};
let options = {};
const url1 = fetch_url_prefix + "/f1timer/gettimerbyyear";
const url2 = fetch_url_prefix + "/f1getstandings/getdriverstandingsbyyear";
const url3 = fetch_url_prefix + "/f1getstandings/getconstructorstandingsbyyear";
// const resultsListUrl = fetch_url_prefix + "/f1raceresults/all";

function findCountryCode(title, country) {
    var code = CountryCodes.find(option => option[title] === country);
    return code ? code.Code.toLowerCase() : "gb";
}

// async function getResultableRacesList(_year) {

//     resultableRacesList = [];

//     let data = {
//         year: _year
//     };

//     let options = { 
//         method: "post",
//         headers: {
//         'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(data)
//     };

//     await nFetch(resultsListUrl, options)
//     .then(respons => respons.clone().json())
//     .then(dat => dat.MRData.RaceTable.Races.map(option => { return(resultableRacesList.push(option.round)) }))
//     .catch(error => console.log(error));

// }

function fetchingAllData(props) {

    if (racesDataList && driversDataList && constructorsDataList && year.toString() === props.year.toString()) {
        // if (promiseAll) {

        const Races = racesDataList && racesDataList.map((option, index) => {
            return(
              <RaceScheduleTable
                    key={index}
                    raceNumber={option.round}
                    country={findCountryCode("Name", option.Circuit.Location.country)}
                    raceName={option.raceName}
                    raceTrack={option.Circuit.circuitName}
                    circuitLink={option.Circuit}
                    raceDate={new Date(option.date).toDateString().substring(4, 10)}
                    raceTime={option.time && new Date(option.date + "T" + option.time).toLocaleTimeString()}
                    yearLink={resultableRacesList && resultableRacesList.includes(option.round) && option.season}
                    raceNumberLink={resultableRacesList && resultableRacesList.includes(option.round) && option.round} 
                    />
            );
        });

        const Drivers = driversDataList && driversDataList.map((option, index) => {
            return(
              <DriverStandingsTable
                    key={index}
                    position={option.position}
                    country={findCountryCode("Nationality", option.Driver.nationality)}
                    driver={option.Driver.givenName + " " + option.Driver.familyName}
                    wins={option.wins}
                    points={option.points}
                    driverLink={option.Driver} />
            );
        });

        const Constructors = constructorsDataList && constructorsDataList.map((option, index) => {
            return(
              <ConstructorStandingsTable
                    key={index}
                    position={option.position}
                    country={findCountryCode("Nationality", option.Constructor.nationality)}
                    constructor={option.Constructor.name}
                    wins={option.wins}
                    points={option.points} />
            );
        });

        return  <div className="inline-tables">
                    <div className="inline-table">
                        {racesDataList && <div>
                            <h2>Season {currentSeason}</h2>
                            {/* <table className={props.isTableLoaded ? "results-table-opened" : "results-table-closed"}> */}
                            <table className="results-table-opened">
                                <tbody>
                                    <tr>
                                        <td>Round</td>
                                        <td>Race Name</td>
                                        <td>Circuit</td>
                                        <td>Date</td>
                                        <td className="td-collapse-1000">Time</td>
                                    </tr>
                                    {Races}
                                </tbody>
                            </table>
                        </div>}
                    </div>
                    <div className="inline-table">
                        {driversDataList && <div>
                            <h2>Driver Standings</h2>
                            {/* <table className={props.isDriverTableLoaded ? "results-table-opened" : "results-table-closed"}> */}
                            <table className="results-table-opened">
                                <tbody>
                                    <tr>
                                        <td>Position</td>
                                        <td>Driver</td>
                                        <td>Wins</td>
                                        <td>Points</td>
                                    </tr>
                                    {Drivers}
                                </tbody>
                            </table>
                        </div>}
                        {constructorsDataList && <div>
                            <h2>Constructor Standings</h2>
                            {/* <table className={props.isConstructorTableLoaded ? "results-table-opened" : "results-table-closed"}> */}
                            <table className="results-table-opened">
                                <tbody>
                                    <tr>
                                        <td>Position</td>
                                        <td>Constructor</td>
                                        <td>Wins</td>
                                        <td>Points</td>
                                    </tr>
                                    {Constructors}
                                </tbody>
                            </table>
                        </div>}
                    </div>
                </div>

    } else {

        param = {
            year: props.year,
        };

        options = { 
            method: "post",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(param)
        }

        promise1 = new Promise(async (resolve) => {

            racesDataList = null;

            resultableRacesList = await getResultableRacesList(props.year);

            await nFetch(url1, options)
            .then(respons => respons.json())
            .then(dat => { 
                racesDataList = dat.MRData.RaceTable.Races;
                currentSeason = dat.MRData.RaceTable.season;
            })
            .catch(error => console.log(error));

            resolve();
        });

        promise2 = new Promise(async (resolve) => {

            driversDataList = null;

            await nFetch(url2, options)
            .then(respons => respons.json())
            .then(dat => { 
                driversDataList = dat.MRData.StandingsTable.StandingsLists[0] ? dat.MRData.StandingsTable.StandingsLists[0].DriverStandings : [];
             })
            .catch(error => console.log(error));

            resolve();
        });

        promise3 = new Promise(async (resolve) => {

            constructorsDataList = null;

            await nFetch(url3, options)
            .then(respons => respons.json())
            .then(dat => { 
                constructorsDataList = dat.MRData.StandingsTable.StandingsLists[0] ? dat.MRData.StandingsTable.StandingsLists[0].ConstructorStandings : [];
            })
            .catch(error => console.log(error));

            resolve();
        });

        year = props.year;
    }

    promiseAll = Promise.all([promise1, promise2, promise3]);

    throw promiseAll;
      
  }

  export default fetchingAllData;