import React from "react";
import DriverByCircuitTable from "./Tables/DriverByCircuitTable";
import CountryCodes from "../country_codes.json";

class DriverByCircuit extends React.Component {

    constructor() {
        super();
        this.state = {
            isLoadingData: false,
            driver: "",
            circuit: "",
            dataFile: [],
            driversList: [],
            isDriverListLoaded: false,
            circuitsList: [],
            isCircuitListLoaded: false,
            isTableLoaded: false,
            selectedDriver: {},
            selectedCircuit: {}
        }
        this.getData = this.getData.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        fetch("/f1getfulldata/getdriverslist")
        .then(respons => respons.clone().json())
        .then(dat => {
          this.setState({
            driversList: dat.MRData.DriverTable.Drivers,
            isDriverListLoaded: true
          });
        })
        .catch(error => console.log(error));

        fetch("/f1getfulldata/getcircuitslist")
        .then(respons => respons.clone().json())
        .then(dat => {
          this.setState({
            circuitsList: dat.MRData.CircuitTable.Circuits,
            isCircuitListLoaded: true
          });
        })
        .catch(error => console.log(error));

    }

    handleChange(event) {

        const {name, value} = event.target;
        this.setState({
            [name]: value,
        });

        if (name === "driver") {
            this.setState({selectedDriver: this.state.driversList.find(option => option.givenName + " " + option.familyName === event.target.value)})
        }

        if (name === "circuit") {
            this.setState({selectedCircuit: this.state.circuitsList.find(option => option.circuitName === event.target.value)})
        }

    }

    getData(event) {

        this.setState({isLoadingData: true})

        let data = {
            position: event.target.value,
            driver: this.state.selectedDriver.driverId,
            circuit: this.state.selectedCircuit.circuitId
        };

        let url = "/f1driverbycircuit";

        fetch(url, { 
            method: "post",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          })
          .then(respons => respons.clone().json())
          .then(dat => {
            this.setState({
              dataFile: dat.MRData.RaceTable.Races,
              isLoadingData: false,
              isTableLoaded: true
            });
          })
          .catch(error => console.log(error));
          
    }

    findCountryCode(title, country) {
      var code = CountryCodes.find(option => option[title] === country);
      return code ? code.Code.toLowerCase() : "gb";
    }

    render() {

        const wins = this.state.dataFile ? this.state.dataFile.map((option, index) => {
            return(<DriverByCircuitTable
                      key={index}
                      season={option.season}
                      round={option.round}
                      raceName={option.raceName}
                      raceDate={option.date}
                      country1={this.findCountryCode("Nationality", option.Results[0].Driver.nationality)}
                      driver={option.Results[0].Driver.givenName + " " + option.Results[0].Driver.familyName}
                      country2={this.findCountryCode("Nationality", option.Results[0].Constructor.nationality)}
                      constructor={option.Results[0].Constructor.name}
                      grid={option.Results[0].grid}
                      position={option.Results[0].position}
                      // laps={option.Results[0].laps}
                      status={option.Results[0].status}
                      // driverLink={option.Results[0].Driver}
                      yearLink={option.season}
                      raceNumberLink={option.round}
                      number={index + 1} />);
        }) : "error";

        const driverListFiltered = this.state.driversList ? this.state.driversList.map(option => {
            return(
              <option
                    key={option.driverId}
                    value={option.givenName + " " + option.familyName}>
              </option>
            );
        }) : "Loading...";

        const circuitListFiltered = this.state.circuitsList ? this.state.circuitsList.map(option => {
            return(
              <option
                    key={option.circuitId}
                    value={option.circuitName}>
              </option>
            );
        }) : "Loading...";

        return(
            <div className="main">
                <p className="description-text">This is driver statistics per circuit. If you want to know how many times did Kimi win at Spa, here you go. Please select the driver and the circuit.</p>
                <h2>Driver By Circuit data</h2>
                <div className="inline-table">
                <label>Driver </label>
                <input 
                        className="test-text-box"
                        type="text" 
                        name="driver" 
                        value={this.state.driver} 
                        onChange={this.handleChange}
                        list="driverlist">
                </input>
                <datalist id="driverlist" className="filtered-list">
                      {driverListFiltered}
                </datalist>
                </div>

                <div className="inline-table">
                <label> Circuit </label>
                <input 
                        className="test-text-box"
                        type="text" 
                        name="circuit" 
                        value={this.state.circuit} 
                        onChange={this.handleChange} 
                        list="circuitlist"
                        style={{"width" : "18em"}}>
                </input>
                <datalist id="circuitlist" className="filtered-list">
                      {circuitListFiltered}
                </datalist>
                </div>

                    <div className="inline-table">
                    <button value="1" onClick={this.getData}>wins</button>
                    <button value="2" onClick={this.getData}>2nd places</button>
                    <button value="3" onClick={this.getData}>3rd places</button>
                    <button value="all" onClick={this.getData}>all results</button>
                    </div>
                    <br></br>
                    <br></br>
                    <label className={this.state.isTableLoaded ? "results-table-opened title" : "results-table-closed title"}>
                          Number of races total: {this.state.dataFile ? this.state.dataFile.length : "loading..."}
                    </label>
                    <br></br>
                    <br></br>
                    {this.state.isLoadingData ? "Loading..."
                    : <table className={this.state.dataFile.length ? "results-table-opened" : "results-table-closed"}>
                        <tbody>
                            <tr>
                                <td>N</td>
                                <td>Season</td>
                                <td className="td-collapse-1000">Date</td>
                                <td>Round</td>
                                <td>Race Name</td>
                                {/* <td>Driver</td> */}
                                <td>Constructor</td>
                                <td>Grid</td>
                                <td>Position</td>
                                {/* <td>Laps</td> */}
                                <td>Status</td>
                            </tr>
                            {wins}
                        </tbody>
                      </table>}
                    <br></br>
                    <br></br>
                    <br></br>
            </div>
        );
    }
}

export default DriverByCircuit;