import React from "react";
import RaceWinnersTable from "./Tables/RaceWinnersTable";
import CountryCodes from "../country_codes.json";

class WinnersBySeason extends React.Component {

    constructor() {
        super();
        this.state = {
          dataFileDrivers: {},
          dataFileCircuits: {},
          dataFileWinners: [],
          driverID: "",
          circuitID: "",
          year: "",
          isLoadingDriver: false,
          isLoadingCircuit: false,
          isLoadingWinners: false,
          isTableLoaded: false,
          seasonList: []
        }
        this.GetWinners = this.GetWinners.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getSeasonsList = this.getSeasonsList.bind(this);
      }

      componentDidMount() {

        this.setState({
          year: new Date().getFullYear()
        }, () => {
          this.getSeasonsList(); 
        });

      }

      getSeasonsList() {

        fetch("/f1seasons/getseasonlist")
          .then(respons => respons.clone().json())
          .then(dat => {
            this.setState({
              seasonList: dat.MRData.SeasonTable.Seasons
            });
          })
          .catch(error => console.log(error));
  
      }
    
      handleChange(event) {
        const {name, value} = event.target;
        this.setState( {[name]: value} )
      }

      GetWinners() {
    
        this.setState({ isLoadingWinners: true });
    
        let data = { year: this.state.year }
    
        let url = "/f1winners";
        
        fetch(url, {
          method: "post",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
        .then(respons => respons.clone().json())
        .then(dat => {
          this.setState({
            dataFileWinners: dat.MRData.RaceTable.Races,
            isLoadingWinners: false,
            isTableLoaded: true
          });
        })
        .catch(error => alert(error));
    
      }

      findCountryCode(title, country) {
        var code = CountryCodes.find(option => option[title] === country);
        return code ? code.Code.toLowerCase() : "gb";
      }
    
        render () {

          const seasons = this.state.seasonList ? this.state.seasonList.map((option, index) => {
            return(<option key={index} value={option.season}>{option.season}</option>)
          }) : <option>{"Loading..."}</option>;

          const raceWinners = this.state.dataFileWinners ? this.state.dataFileWinners.map((option, index) => {
            return(
              <RaceWinnersTable
                    key={index}
                    raceNumber={option.round}
                    raceCountry={option.Circuit.Location.country}
                    raceName={option.raceName}
                    country1={this.findCountryCode("Name", option.Circuit.Location.country)}
                    raceTrack={option.Circuit.circuitName}
                    raceDate={new Date(option.date).toDateString().substring(4, 10)}
                    country2={this.findCountryCode("Nationality", option.Results[0].Driver.nationality)}
                    raceWinner={option.Results[0].Driver.givenName + " " + option.Results[0].Driver.familyName}
                    country3={this.findCountryCode("Nationality", option.Results[0].Constructor.nationality)}
                    constructor={option.Results[0].Constructor.name}
                    driverLink={option.Results[0].Driver}
                    circuitLink={option.Circuit}
                    yearLink={option.season}
                    raceNumberLink={option.round} />
             
            );
          }) : "error!";
    
            return (
              <div className="main">
                    <p className="description-text">Here you can find the winners of every race in the season. Please select the season.</p>
                    <h2>Race winners by season</h2>
                    <label>Year </label>
                    <select 
                      className="drop-down-box"
                      name="year"
                      value={this.state.year}
                      onChange={this.handleChange}>
                        {seasons.reverse()}
                    </select>
                    <button onClick={this.GetWinners}>show me the winners</button><br></br>
                    <br></br>
                    <br></br>
                    {this.state.isLoadingWinners ? "Loading..."
                    : <table className={this.state.isTableLoaded ? "results-table-opened" : "results-table-closed"}>
                        <tbody>
                          <tr>
                            <td>Round</td>
                            <td className="td-collapse-1000">Date</td>
                            {/* <td>Country</td> */}
                            <td>Race Name</td>
                            <td>Circuit</td>
                            {/* <td>Date</td> */}
                            <td>Winner</td>
                            <td>Constructor</td>
                          </tr>
                          {raceWinners}
                        </tbody>
                      </table>}
                      <br></br>
                      <br></br>
                      <br></br>
              </div>
            );
          }

}

export default WinnersBySeason;