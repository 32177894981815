import React from "react"
import { Link } from "react-router-dom";

function ConstructorWinnerTable(props) {

    return(
                <tr>
                    <td>{props.number}</td>
                    <td>{props.yearLink ? <Link to={{
                                                pathname: "/",
                                                param: {
                                                    pYear: props.yearLink
                                                }
                                            }}>{props.season}</Link> : props.season}</td>
                    <td className="td-collapse-1000">{props.raceDate}</td>
                    <td>{props.round}</td>
                    <td>{(props.yearLink && props.raceNumberLink) ? <Link to={{
                                                                        pathname: "/raceresults",
                                                                        param: {
                                                                            pYear: props.yearLink,
                                                                            pNumber: props.raceNumberLink
                                                                        }
                                                                    }}>{props.raceName}</Link> : props.raceName}</td>
                    <td><div className={"flag-pic " + props.country1}></div>
                        {props.circuitLink ? <Link to={{
                                                pathname: "/circuits",
                                                param: {
                                                    pCircuit: props.circuitLink
                                                }
                                            }}>{props.circuitName}</Link> : props.circuitName}</td>
                    <td><div className={"flag-pic " + props.country2}></div>
                        {props.driverLink ? <Link to={{
                                                pathname: "/drivers",
                                                param: {
                                                    pDriver: props.driverLink
                                                }
                                            }}>{props.driver}</Link> : props.driver}</td>
                    <td>{props.grid}</td>
                    <td>{props.position}</td>
                    <td>{props.points}</td>
                </tr>
    );
}

export default ConstructorWinnerTable;