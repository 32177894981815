import React, { Suspense } from "react";
import nFetch from "node-fetch";
import CountryCodes from "../../country_codes.json";
import fetch_url_prefix from "./env_url";
import FetchWiki from "./fetchWiki";
import getLastResultableRaceWinner from "./getLastResultableRace";
import WinsCountTable from "../Tables/WinsCountTable";
import CircuitWinnerTable from "../Tables/CircuitWinnerTable";

let circuitWikiUrl = null;
let dataFileCircuits = null;
let promiseCircuit = null;
let promiseLastResultableRace = null;
let lastRaceData = null;
let circuitID = null;
let circuitName = null;
const url = fetch_url_prefix + "/f1circuits";

function findCountryCode(title, country) {
    var code = CountryCodes.find(option => option[title] === country);
    return code ? code.Code.toLowerCase() : "gb";
}

function fetchCircuits(props) {

    // console.log(driverID + " / " + props.driverID + " ----- " + position + " / " + props.position);
    // console.log(dataFileDrivers && driverID === props.driverID && position === props.position);
    // console.log(driverName);

    if (dataFileCircuits && circuitID === props.circuitID) {
        
        const wins = dataFileCircuits && dataFileCircuits.map((option, index) => {
            return(<CircuitWinnerTable
                      key={index}
                      season={option.season}
                      round={option.round}
                      raceName={option.raceName}
                      raceDate={new Date(option.date).toDateString().substring(4, 10)}
                      country1={findCountryCode("Nationality", option.Results[0].Driver.nationality)}
                      driver={option.Results[0].Driver.givenName + " " + option.Results[0].Driver.familyName}
                      country2={findCountryCode("Nationality", option.Results[0].Constructor.nationality)}
                      constructor={option.Results[0].Constructor.name}
                      yearLink={option.season}
                      raceNumberLink={option.round}
                      driverLink={option.Results[0].Driver}
                      number={index + 1} />);
        });

        let driverWinsArray = [{
            wins: 1, 
            Driver: {
              driverId: "id",
              givenName: "givenName",
              familyName: "familyName",
              nationality: "nationality"
            }
        }];

        let driverExists = true;

        dataFileCircuits && dataFileCircuits.map((option, index) => {

            for (let element of driverWinsArray) {

              if (element.Driver.givenName + " " + element.Driver.familyName === option.Results[0].Driver.givenName + " " + option.Results[0].Driver.familyName) {
                element.wins++;
                driverExists = true;
                break;
              }
              else driverExists = false;

            }

            !driverExists && driverWinsArray.push({
              wins: 1,
              Driver: {
                driverId: option.Results[0].Driver.driverId,
                givenName: option.Results[0].Driver.givenName,
                familyName: option.Results[0].Driver.familyName,
                nationality: option.Results[0].Driver.nationality
              }
            });

            return(driverWinsArray);

        });

        const winsCount = driverWinsArray.filter(a => a.Driver.driverId !== "id").sort((a, b) => a.wins < b.wins ? 1 : -1).map((option, index) => {

            return(<WinsCountTable
                      key={index}
                      driver={option.Driver.givenName + " " + option.Driver.familyName}
                      wins={option.wins}
                      country={findCountryCode("Nationality", option.Driver.nationality)}
                      driverLink={option.Driver}/>);

        });

        return  <div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <FetchWiki link={circuitWikiUrl} />
                    </Suspense>
                    <label className="results-table-opened title">Number of races total: {dataFileCircuits.length}</label>
                    <div className="inline-tables">
                      <div className="inline-table">
                        <div><h2>{circuitName + " - Race Wins"}</h2>
                            <table className="results-table-opened">
                              <tbody>
                                <tr>
                                    <td>N</td>
                                    <td>Season</td>
                                    <td className="td-collapse-1000">Date</td>
                                    <td>Round</td>
                                    <td>Race Name</td>
                                    
                                    <td>Winner</td>
                                    <td>Constructor</td>
                                </tr>
                                {wins}
                              </tbody>
                            </table>
                          </div>
                      </div>
                        <div className="inline-table">
                        <div><h2>Wins Count</h2>
                            <table className="results-table-opened">
                              <tbody>
                                <tr>
                                  <td>Driver</td>
                                  <td>Wins</td>
                                </tr>
                                {winsCount}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                </div>

    } else {

        // driverID = null;
        // position = null;

        // console.log(driverID + " / " + props.driverID + " ----- " + position + " / " + props.position);

        promiseLastResultableRace = new Promise(async (resolve) => {

            lastRaceData = await getLastResultableRaceWinner();

            let data = {
                circuit: props.circuitID ? props.circuitID : lastRaceData.Circuit.circuitId
            };

            resolve(data);
        });

        // console.log(await promiseLastResultableRace);

        promiseCircuit = new Promise(async (resolve) => {

            // dataFileDrivers = null;

            // let lastRaceData = await getLastResultableRaceWinner();

            // let data = {
            //     position: props.position ? props.position : "1",
            //     driver: props.driverID ? props.driverID : lastRaceData.Results[0].Driver.driverId
            // };

            // console.log(await promiseLastResultableRace);

            let param = await promiseLastResultableRace;

            let options = { 
                method: "post",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(param)
            }

            // console.log(data);
            // console.log(driverName);

            dataFileCircuits = null;
      
            await nFetch(url, options)
                .then(respons => respons.json())
                .then(dat => {
                    dataFileCircuits = dat.MRData.RaceTable.Races ? dat.MRData.RaceTable.Races : [];
                    circuitWikiUrl = dat.MRData.RaceTable.Races[0].Circuit && dat.MRData.RaceTable.Races[0].Circuit.url;
                    circuitName = dat.MRData.RaceTable.Races[0].Circuit && dat.MRData.RaceTable.Races[0].Circuit.circuitName;
                    circuitID = props.circuitID;
                })
                .catch(error => console.log(error));

            // console.log(driverName);
    
            resolve();
        });
        
        // driverID = props.driverID;
        // position = props.position;
        // driverName = props.driverName
    }

    throw promiseCircuit;

    // promiseAll = Promise.all([promiseDriver, promiseLastResultableRace]);

    // throw promiseAll;

}

export default fetchCircuits;

