import React from "react";
// import { Link } from "react-router-dom";

function WikiSummaryTable(props) {

    return(
        <table className="wiki-table">
            <tbody>
                <tr>
                    {props.picture && <td><img src={props.picture} alt={""}/></td>}
                    {props.wikilink && <td>
                        <p>{props.text}</p>
                        <p className="wiki-link"><a href={props.wikilink} target="_blank" rel="noopener noreferrer">read more</a></p>
                    </td>}
                </tr>
            </tbody>
        </table>
    )
}

export default WikiSummaryTable;