import React, { Suspense } from "react";
import FetchCircuits from "./Funcs/fetchCircuits";

class Circuits extends React.Component {

    constructor() {
        super();
        this.state = {
          dataFileDrivers: {},
          dataFileCircuits: [],
          dataFileWinners: [],
          dataFileWiki: {},
          driverID: "",
          circuitName: "",
          year: "",
          isLoadingDriver: false,
          isLoadingCircuit: false,
          isLoadingWinners: false,
          isLoadingWiki: false,
          isCircuitListLoaded: false,
          isTableLoaded: false,
          circuitsList: [],
          selectedCircuit: {},
          selectedCircuitID: "",
        }
        this.getLastResultsCircuit = this.getLastResultsCircuit.bind(this);
        this.handleChange = this.handleChange.bind(this);
      }
    
      handleChange(event) {
        const {name, value} = event.target;
        this.setState({
          [name]: value,
          // selectedCircuit: this.state.circuitsList.find(option => option.circuitName === event.target.value)
        });

        let circuitFind = this.state.circuitsList.find(option => option.circuitName === event.target.value);
        if (circuitFind)
          this.setState({
                selectedCircuitID: circuitFind.circuitId,
                circuitName: circuitFind.circuitName
          });
      }

      componentDidMount() {

        let url = "/f1getfulldata/getcircuitslist";

        fetch(url)
        .then(respons => respons.clone().json())
        .then(dat => {
          this.setState({
            circuitsList: dat.MRData.CircuitTable.Circuits,
            isCircuitListLoaded: true
          });
        })
        .then(this.props.location.param ? this.setState({
                                                  // selectedCircuit: this.props.location.param.pCircuit,
                                                  selectedCircuitID: this.props.location.param.pCircuit.circuitId,
                                                  circuitName: this.props.location.param.pCircuit.circuitName
                                              })
                                              : this.getLastResultsCircuit()
        )
        .catch(error => console.log(error));

      }

      getLastResultsCircuit() {

        fetch("/f1timer/getlastresultablerace")
        .then(respons => respons.clone().json())
        .then(dat => { 
            dat.MRData.RaceTable.Races[0].Circuit && this.setState({
              selectedCircuitID: dat.MRData.RaceTable.Races[0].Circuit.circuitId,
              circuitName: dat.MRData.RaceTable.Races[0].Circuit.circuitName
              // driverPosition:dat.MRData.RaceTable.position
            });
        })
        .catch(error => console.log(error));
      }

        render () {

          const circuitListFiltered = this.state.circuitsList ? this.state.circuitsList.map(option => {
            return(
              <option
                    key={option.circuitId}
                    value={option.circuitName}>
              </option>
            );
          }) : "Loading...";

            return (
              <div className="main">
                    <p className="description-text">Here you can find race statistics of any F1 circuit with the links to the race results. Please select the circuit.</p>
                    <h2>Circuits - Races and Winners</h2>
                    <label>Circuit </label>
                    <input 
                          className="test-text-box"
                          type="text" 
                          name="circuitName" 
                          value={this.state.circuitName} 
                          onChange={this.handleChange}
                          list="circuitlist"
                          style={{"width" : "18em"}}>
                    </input>

                    <datalist id="circuitlist" className="filtered-list">
                      {circuitListFiltered}
                    </datalist>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Suspense fallback={<div>Loading...</div>}>
                      <FetchCircuits
                        circuitID={this.state.selectedCircuitID} />
                    </Suspense>
                    <br></br>
                    <br></br>
                    <br></br>
              </div>
            );
          }

}

export default Circuits;
