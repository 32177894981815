import nFetch from "node-fetch";
import fetch_url_prefix from "./env_url";

let raceData = null;
const url = fetch_url_prefix + "/f1timer/getlastresultablerace";

async function getLastResultableRace() {

    await nFetch(url)
        .then(respons => respons.json())
        .then(dat => { 
            raceData = dat.MRData.RaceTable.Races[0];
        })
        .catch(error => console.log(error));

        return raceData;

}

export default getLastResultableRace;