import React from "react";
import { Link } from "react-router-dom";

const colorStyle = (status) => {
    if (status.includes("Finished")) return {color: "darkgreen"};
    else if (status.includes("Lap")) return {color: "darkorange"};
    else return {color: "black"};
}

function RaceResultsTable(props) {

    return(
                <tr>
                    <td>{props.position}</td>
                    <td>{props.points}</td>
                    <td><div className={"flag-pic " + props.country1}></div>
                        {props.driverLink ? <Link to={{
                                                pathname: "/drivers",
                                                param: {
                                                    pDriver: props.driverLink
                                                }
                                            }}>{props.driver}</Link> : props.driver}</td>
                    <td><div className={"flag-pic " + props.country2}></div>{props.constructor}</td>
                    <td><span style={colorStyle(props.status)}>{props.status}</span></td>
                    <td>{props.laps}</td>
                </tr>
    )
}

export default RaceResultsTable;