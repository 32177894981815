import React, { Suspense } from "react";
import RaceResultsTable from "../Tables/RaceResultsTable";
import QualiTable from "../Tables/QualiTable";
import nFetch from "node-fetch";
import CountryCodes from "../../country_codes.json";
import fetch_url_prefix from "./env_url";
import FetchWiki from "./fetchWiki";
import getLastResultableRace from "./getLastResultableRace";

let year = null;
let raceNumber = null;
let displayRaceSeason = null;
let displayRaceName = null;
let raceWikiUrl = null;
let qualiData = null;
let raceData = null;
let promiseQual = null;
let promiseRace = null;
let promiseAll = null;
let promiseLastResultableRace = null;
let lastRaceData = null;
const qualiUrl = fetch_url_prefix + "/f1raceresults/quali";
const raceUrl = fetch_url_prefix + "/f1raceresults";

function findCountryCode(title, country) {
    var code = CountryCodes.find(option => option[title] === country);
    return code ? code.Code.toLowerCase() : "gb";
}

function getResults(props) {

    if (raceData && qualiData && year.toString() === props.year.toString() && raceNumber.toString() === props.raceNumber.toString()) {

        const raceResults = raceData.map((option, index) => {
            return(
              <RaceResultsTable
                    key={index}
                    position={option.position}
                    points={option.points}
                    country1={findCountryCode("Nationality", option.Driver.nationality)}
                    driver={option.Driver.givenName + " " + option.Driver.familyName}
                    country2={findCountryCode("Nationality", option.Constructor.nationality)}
                    constructor={option.Constructor.name}
                    status={option.status}
                    laps={option.laps}
                    driverLink={option.Driver}/>
            );
          });

          const qualiResults = qualiData.map((option, index) => {
            return(
              <QualiTable
                    key={index}
                    position={option.position}
                    country1={findCountryCode("Nationality", option.Driver.nationality)}
                    driver={option.Driver.givenName + " " + option.Driver.familyName}
                    country2={findCountryCode("Nationality", option.Constructor.nationality)}
                    constructor={option.Constructor.name}
                    q1time={option.Q1 && option.Q1}
                    q2time={option.Q2 && option.Q2}
                    q3time={option.Q3 && option.Q3}
                    driverLink={option.Driver}/>
            );
          });

        return  <div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <FetchWiki link={raceWikiUrl} />
                    </Suspense>
                    <h2>{displayRaceSeason + " " + displayRaceName}</h2>
                    {raceResults.length > 0 && <div className="inline-tables">
                        <div className="inline-table">
                            <div>
                                <h2>Race Results</h2>
                                <table className="results-table-opened">
                                    <tbody>
                                        <tr>
                                            <td>Position</td>
                                            <td>Points</td>
                                            <td>Driver</td>
                                            <td>Constructor</td>
                                            <td>Status</td>
                                            <td>Laps</td>
                                        </tr>
                                        {raceResults}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="inline-table">
                            <div>
                                <h2>Qualifying Results</h2>
                                <table className="results-table-opened">
                                    <tbody>
                                        <tr>
                                            <td>Position</td>
                                            <td>Driver</td>
                                            <td>Constructor</td>
                                            <td>Q1</td>
                                            <td>Q2</td>
                                            <td>Q3</td>
                                        </tr>
                                        {qualiResults}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
                </div>

    } else {

        promiseLastResultableRace = new Promise(async (resolve) => {

            lastRaceData = await getLastResultableRace();

            let data = {
                year: props.year ? props.year : lastRaceData.season,
                raceNumber: props.raceNumber ? props.raceNumber : lastRaceData.round
            };

            resolve(data);
        })

        promiseRace = new Promise(async (resolve) => {

            let param = await promiseLastResultableRace;

            let options = { 
                method: "post",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(param)
            }

            raceData = null;
            
            await nFetch(raceUrl, options)
            .then(respons => respons.json())
            .then(dat => {
                  raceData = dat.MRData.RaceTable.Races[0] ? dat.MRData.RaceTable.Races[0].Results : [];
                  displayRaceSeason = dat.MRData.RaceTable.Races[0] ? dat.MRData.RaceTable.Races[0].season : lastRaceData.season;
                  displayRaceName = dat.MRData.RaceTable.Races[0] ? dat.MRData.RaceTable.Races[0].raceName : lastRaceData.raceName + " - Last race";
                  raceWikiUrl = dat.MRData.RaceTable.Races[0] ? dat.MRData.RaceTable.Races[0].url : lastRaceData.url;
                //   console.log(displayRaceName);
            })
            .catch(error => console.log(error));
    
            resolve();
        });
    
        promiseQual = new Promise(async (resolve) => {

            let options = { 
                method: "post",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(await promiseLastResultableRace)
            }

            qualiData = null;
    
            await nFetch(qualiUrl, options)
            .then(respons => respons.json())
            .then(dat => { qualiData = dat.MRData.RaceTable.Races[0] ? dat.MRData.RaceTable.Races[0].QualifyingResults : [] })
            .catch(error => console.log(error));
    
            resolve();
        });
    
        year = props.year;
        raceNumber = props.raceNumber;
    }

    promiseAll = Promise.all([promiseRace, promiseQual]);

    throw promiseAll;

}

export default getResults;

