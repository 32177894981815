import React from "react";
import Drivers from "./Drivers";
import Circuits from "./Circuits";
import WinnersBySeason from "./WinnersBySeason";
import Constructors from "./Constructors";
import RaceResults from "./RaceResults";
import DriverByCircuit from "./DriverByCircuit";
import StartPage from "./StartPage";
import { Route, Switch } from "react-router-dom";

class MainContent extends React.Component {

    constructor() {
        super();
        this.state = {
            driversList: [],
            constructorsList: [],
            circuitsList: [],
            isDriverListLoaded: false,
            isConstructorsListLoaded: false,
            isCircuitListLoaded: false
        }
    }

    componentDidMount() {

        fetch("/f1getfulldata/getdriverslist")
        .then(respons => respons.clone().json())
        .then(dat => {
          this.setState({
            driversList: dat.MRData.DriverTable.Drivers,
            isDriverListLoaded: true
          });
        })
        .catch(error => console.log(error));

        fetch("/f1getfulldata/getconstructorslist")
        .then(respons => respons.clone().json())
        .then(dat => {
          this.setState({
            constructorsList: dat.MRData.ConstructorTable.Constructors,
            isConstructorsListLoaded: true
          });
        })
        .catch(error => console.log(error));

        fetch("/f1getfulldata/getcircuitslist")
        .then(respons => respons.clone().json())
        .then(dat => {
          this.setState({
            circuitsList: dat.MRData.CircuitTable.Circuits,
            isCircuitListLoaded: true
          });
        })
        .catch(error => console.log(error));

    }

    render() {

      // if (this.state.isDriverListLoaded && this.state.isConstructorsListLoaded && this.state.isCircuitListLoaded) 
      //   console.log(
      //     "drivers:" + this.state.driversList.length +
      //     " constructors:" + this.state.constructorsList.length +
      //     " circuits:" + this.state.circuitsList.length);

        return(
            <div 
              className="main-content"
              onClick={this.props.showmenu}>
                <Switch>
                    <Route exact path="/" component={StartPage}></Route>
                    <Route path="/drivers" component={Drivers}></Route>
                    <Route path="/circuits" component={Circuits}></Route>
                    <Route path="/winnersbyseason" component={WinnersBySeason}></Route>
                    <Route path="/constructors" component={Constructors}></Route>
                    <Route path="/raceresults" component={RaceResults}></Route>
                    <Route path="/driverbycircuit" component={DriverByCircuit}></Route>
                </Switch>
            </div>
        );
    }
  
}

export default MainContent;