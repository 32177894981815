import React, { Suspense } from "react";
import nFetch from "node-fetch";
import CountryCodes from "../../country_codes.json";
import fetch_url_prefix from "./env_url";
import FetchWiki from "./fetchWiki";
import getLastResultableRaceWinner from "./getLastResultableRace";
import DriverWinnerTable from "../Tables/DriverWinnerTable";

// let driverWikiUrl = null;
let dataFileDrivers = null;
let promiseLastResultableRace = null;
let promiseDriver = null;
let lastRaceData = null;
let driverID = null;
let position = null;
let driverWikiUrl = null;
let driverName = null;
let label = null;
let positionFetched = null;
const url = fetch_url_prefix + "/f1drivers";

function findCountryCode(title, country) {
    var code = CountryCodes.find(option => option[title] === country);
    return code ? code.Code.toLowerCase() : "gb";
}

function fetchDrivers(props) {

    // console.log(driverID + " / " + props.driverID + " ----- " + position + " / " + props.position);
    // console.log(dataFileDrivers && driverID === props.driverID && position === props.position);
    // console.log(driverName);

    if (dataFileDrivers && driverID === props.driverID && position === props.position) {

        if (positionFetched === "1") { label = "Wins of " + driverName; }
        else if (positionFetched === "2") { label = "2nd places of " + driverName; }
        else if (positionFetched === "3") { label = "3rd places of " + driverName; }
        else { label = "All races of " + driverName; }
        
        const wins = dataFileDrivers.map((option, index) => {
            return(<DriverWinnerTable
                        key={index}
                        season={option.season}
                        round={option.round}
                        raceName={option.raceName}
                        country1={findCountryCode("Name", option.Circuit.Location.country)}
                        circuitName={option.Circuit.circuitName}
                        raceDate={new Date(option.date).toDateString().substring(4, 10)}
                        grid={option.Results[0].grid}
                        position={option.Results[0].position}
                        points={option.Results[0].points}
                        country2={findCountryCode("Nationality", option.Results[0].Constructor.nationality)}
                        constructor={option.Results[0].Constructor.name}
                        circuitLink={option.Circuit}
                        yearLink={option.season}
                        raceNumberLink={option.round}
                        number={index + 1} />);
        });

        // console.log(props.driverWikiUrl);

        return  <div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <FetchWiki link={driverWikiUrl} />
                    </Suspense>
                    <div className="inline-tables">
                        <div className="inline-table"> 
                            <div>
                                <h2>{label + " - races total: " + dataFileDrivers.length}</h2>
                                <table className="results-table-opened">
                                    <tbody>
                                        <tr>
                                            <td>N</td>
                                            <td>Season</td>
                                            <td className="td-collapse-1000">Date</td>
                                            <td>Round</td>
                                            <td>Race Name</td>
                                            <td>Circuit</td>
                                            <td>Constructor</td>
                                            <td>Grid</td>
                                            <td>Position</td>
                                            <td>Points</td>
                                        </tr>
                                        {wins}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

    } else {

        // console.log(driverID + " / " + props.driverID + " ----- " + position + " / " + props.position);

        promiseLastResultableRace = new Promise(async (resolve) => {

            lastRaceData = await getLastResultableRaceWinner();

            let data = {
                position: props.position ? props.position : "1",
                driver: props.driverID ? props.driverID : lastRaceData.Results[0].Driver.driverId
            };

            // driverName = props.driverName ? props.position : lastRaceData.Results[0].Driver.givenName + " " + lastRaceData.Results[0].Driver.familyName;
            // driverWikiUrl = props.driverWikiUrl ? props.driverWikiUrl : lastRaceData.Results[0].Driver.url;
            // position = "1";

            resolve(data);
        });

        promiseDriver = new Promise(async (resolve) => {

            let param = await promiseLastResultableRace;

            let options = { 
                method: "post",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(param)
            }

            dataFileDrivers = null;

            await nFetch(url, options)
              .then(respons => respons.json())
              .then(dat => {
                    dataFileDrivers = dat.MRData.RaceTable.Races ? dat.MRData.RaceTable.Races : [];
                    driverWikiUrl = dat.MRData.RaceTable.Races[0].Results[0].Driver && dat.MRData.RaceTable.Races[0].Results[0].Driver.url;
                    driverName = dat.MRData.RaceTable.Races[0].Results[0].Driver && dat.MRData.RaceTable.Races[0].Results[0].Driver.givenName + " " + dat.MRData.RaceTable.Races[0].Results[0].Driver.familyName;
                    positionFetched = dat.MRData.RaceTable.position && dat.MRData.RaceTable.position;
                    driverID = props.driverID;
                    position = props.position;
                    // driverName = props.driverName;
                    // driverWikiUrl = props.driverWikiUrl;
              })
              .catch(error => console.log(error));

            // console.log(driverName);
    
            resolve();
        });
    }

    throw promiseDriver;

}

export default fetchDrivers;

