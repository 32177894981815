import React from "react";
import NewsFeedItem from "../Tables/NewsFeedItem";
import nFetch from "node-fetch";
import { parseString } from "xml2js";
import fetch_url_prefix from "./env_url";

let newsFeed = [];
const numberOfNewsItems = 8;
let rss = null;
let promise = null;
const url = fetch_url_prefix + "/fetchnews";

function fetchRSS() {

    if (rss) {

        parseString(rss, (err, result) => {
            // console.log(JSON.stringify(result));
            result.rss.channel[0].item.forEach((element, index) => {
                    return(newsFeed[index] = index < numberOfNewsItems && <NewsFeedItem 
                        key={index}
                        mediaUrl={element.enclosure[0].$.url}
                        title={element.title[0]}
                        // description={element.querySelector("description").textContent}
                        description={element.description[0].substring(0, element.description[0].indexOf("<br>"))}
                        // description={doc.firstChild.innerHTML.replace(" ...Keep reading", "")}
                        date={new Date(element.pubDate[0]).toLocaleDateString()}
                        link={element.link[0]} 
                        />
                    )
                }); 
        });

        return  <div className="news-feed">
                    <p className="news-title">Formula 1 news</p>
                    {newsFeed}
                </div>

    } else {

        promise = new Promise(async (resolve) => {

            await nFetch(url)
            .then(respons => respons.text())
            .then(dat => {
                // let parser = new DOMParser();
                // rss = parser.parseFromString(dat, "text/xml");
                rss = dat;
            })
            .catch(error => console.log(error));

            resolve();
        });

    }

    throw promise;
      
  }

  export default fetchRSS;

  //Motorsport
      // this.state.rss && this.state.rss.querySelectorAll("item").forEach((element, index) => {
      //   return(newsFeed[index] = index <= numberOfNewsItems-1 && <NewsFeedItem 
      //     key={index}
      //     mediaUrl={element.querySelector("enclosure").getAttribute("url")}
      //     title={element.querySelector("title").textContent}
      //     // description={element.querySelector("description").textContent}
      //     description={element.querySelector("description").textContent.substring(0, element.querySelector("description").textContent.indexOf("<br>"))}
      //     // description={doc.firstChild.innerHTML.replace(" ...Keep reading", "")}
      //     date={new Date(element.querySelector("pubDate").textContent).toLocaleDateString()}
      //     link={element.querySelector("link").textContent} />
      //   )
      // }); 