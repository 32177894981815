import nFetch from "node-fetch";
import fetch_url_prefix from "./env_url";

let resultableRacesList = [];
const resultsListUrl = fetch_url_prefix + "/f1raceresults/all";

async function getResultableRacesList(year) {

    resultableRacesList = [];

    let data = {
        year: year
    };

    let options = { 
        method: "post",
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    await nFetch(resultsListUrl, options)
    .then(respons => respons.json())
    .then(dat => dat.MRData.RaceTable.Races.map(option => { return(resultableRacesList.push(option.round)) }))
    .catch(error => console.log(error));

    return resultableRacesList;

}

export default getResultableRacesList;