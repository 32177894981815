import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "./Flags.css";
import "./App.css";
// import StartPage from "./comps/StartPage";

// ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.hydrate(
    // <StyleContext.Provider value={{ insertCss }}>
    // <Frontload>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    // </Frontload>
    // </StyleContext.Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
