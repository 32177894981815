import React from 'react';
// import React from 'react';
import "./Flags.css";
import "./App.css";
import Header from "./comps/Header";
import Footer from "./comps/Footer";
import Sidebar from "./comps/Sidebar";
import MainContent from './comps/MainContent';
import { Helmet } from "react-helmet";

class App extends React.Component {

  constructor() {
    super();
    this.state = {
      ifSidebarOpened: false
    }
    this.showSideBar = this.showSideBar.bind(this);
  }

  showSideBar() {
    this.setState(prev => {
      return { ifSidebarOpened: !prev.ifSidebarOpened }
    });
  }

  front() {
    fetch("/f1getfulldata/getdriverslist")
        .then(respons => respons.clone().json())
        .catch(error => console.log(error));
  }

  render() {
    return (
      // frontloadConnect(this.front)(
      <div>
        {/* <FrontLoad> */}
        {/* <BrowserRouter> */}
        <Helmet>
              <title>F1 Reactor</title>
              <link rel="canonical" href="https://f1reactor.com" />
              <link rel="shortcut icon" href="/static/favicon.ico"/>
              <link rel="manifest" href="/static/manifest.json"/>
        </Helmet>
          <Header 
              showmenu={this.showSideBar}
              isopened={this.state.ifSidebarOpened} />
          <Sidebar 
              isopened={this.state.ifSidebarOpened}
              showmenu={this.state.ifSidebarOpened ? this.showSideBar : undefined}/>
          <MainContent 
              showmenu={this.state.ifSidebarOpened ? this.showSideBar : undefined}/>
          <Footer />
        {/* </BrowserRouter> */}
        {/* </FrontLoad> */}
      </div>
    );
  }
  
}

// module.exports = App;
export default App;
// export default withStyles(appcss, flagscss)(App);


