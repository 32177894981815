import React from "react";
import { Link } from "react-router-dom";

const colorStyle = (status) => {
    if (status.includes("Finished")) return {color: "darkgreen"};
    else if (status.includes("Lap")) return {color: "darkorange"};
    else return {color: "black"};
}

function DriverByCircuitTable(props) {

    return(
                <tr>
                    <td>{props.number}</td>
                    <td>{props.yearLink ? <Link to={{
                                                pathname: "/",
                                                param: {
                                                    pYear: props.yearLink
                                                }
                                            }}>{props.season}</Link> : props.season}</td>
                    <td className="td-collapse-1000">{props.raceDate}</td>
                    <td>{props.round}</td>
                    <td>{(props.yearLink && props.raceNumberLink) ? <Link to={{
                                                                        pathname: "/raceresults",
                                                                        param: {
                                                                            pYear: props.yearLink,
                                                                            pNumber: props.raceNumberLink
                                                                        }
                                                                    }}>{props.raceName}</Link> : props.raceName}</td>
                    {/* <td><div className={"flag-pic " + props.country1}></div>
                        {props.driverLink ? <Link to={{
                                                pathname: "/drivers",
                                                param: {
                                                    pDriver: props.driverLink
                                                }
                                            }}>{props.driver}</Link> : props.driver}</td> */}
                    <td><div className={"flag-pic " + props.country2}></div>{props.constructor}</td>
                    <td>{props.grid}</td>
                    <td>{props.position}</td>
                    <td><span style={colorStyle(props.status)}>{props.status}</span></td>
                </tr>
    )
}

export default DriverByCircuitTable;