import React from "react";
import { Link } from "react-router-dom";

function RaceScheduleTable(props) {

    return(
                <tr>
                    <td>{props.raceNumber}</td>
                    <td>{(props.yearLink && props.raceNumberLink) ? <Link to={{
                                                                                pathname: "/raceresults",
                                                                                param: {
                                                                                    pYear: props.yearLink,
                                                                                    pNumber: props.raceNumberLink
                                                                                }
                                                                            }}>{props.raceName}</Link> : props.raceName}</td>
                    <td style={{"minWidth": "16em"}}><div className={"flag-pic " + props.country}></div>
                        {props.circuitLink ? <Link to={{
                                                pathname: "/circuits",
                                                param: {
                                                    pCircuit: props.circuitLink
                                                }
                                            }}>{props.raceTrack}</Link> : props.raceTrack}</td>
                    <td>{props.raceDate}</td>
                    <td className="td-collapse-1000">{props.raceTime}</td>
                </tr>
    )
}

export default RaceScheduleTable;