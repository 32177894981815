import React from "react";

class Footer extends React.Component {

    render () {
        return (
          <div className="footer">
              <p>
                Please send your suggestions to roman.yamilov@gmail.com
              </p>
              <p>
                Special thanks to <a href="http://ergast.com/mrd/" target="_blank" rel="noopener noreferrer">Ergast Developer API</a>
              </p>
          </div>
        );
      }

}

export default Footer;