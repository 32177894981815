import React from "react";
import CountryCodes from "../country_codes.json";
// import Transmit from "react-transmit";

class Timer extends React.Component {

    constructor() {
        super();
        this.state = {
            currentDateTime: Date.now(),
            racesDataList: [],
            isRacesListLoaded: false,
            nextRace: {},
            currentYearInFile: "",
            flagClass: ""
        }
        this.getNextRace = this.getNextRace.bind(this);
    }

    componentDidMount() {

        this.getNextRace();

        setInterval(() => {
            this.setState({currentDateTime: Date.now()});
        }, 1000);

    }

    getNextRace() {

        this.setState({ isRacesListLoaded: false });

        fetch("/f1timer/gettimer")
        // .then(resp => console.log(resp.body.toString()))
        .then(respons => respons.clone().json())
        .then(dat => {
          this.setState({
            currentYearInFile: dat.MRData.RaceTable.season,
            racesDataList: dat.MRData.RaceTable.Races,
            isRacesListLoaded: true
          });
        })
        .then(() => {
            this.state.racesDataList.find(option => new Date(option.date + "T" + option.time) > this.state.currentDateTime) 
                ?
                    this.setState({
                        nextRace: this.state.racesDataList.find(option => new Date(option.date + "T" + option.time) > this.state.currentDateTime)
                    }, () => {
                        this.setState({
                            flagClass: this.findCountryCode("Name", this.state.nextRace.Circuit.Location.country)
                        })
                    })
                : 
                    fetch("/f1timer/gettimerbyyear", { 
                        method: "post",
                        headers: {
                        'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            year: this.state.currentYearInFile === this.state.currentDateTime ?
                            new Date().getFullYear() + 1
                            : new Date().getFullYear(),
                        })
                    })
                    .then(respons => respons.clone().json())
                    .then(dat => {
                    this.setState({
                        racesDataList: dat.MRData.RaceTable.Races,
                        isRacesListLoaded: true
                    });
                    })
                    .then(() => {
                        this.setState({
                            nextRace: this.state.racesDataList.find(option => new Date(option.date + "T" + option.time) > this.state.currentDateTime)
                        }, () => {
                            this.setState({
                                flagClass: this.findCountryCode("Name", this.state.nextRace ? this.state.nextRace.Circuit.Location.country : "Australia")
                            })
                        })
                    })
                // .then(() => {
                //     this.setState({
                //         flagClass: this.findCountryCode("Name", this.state.nextRace.Circuit.Location.country)
                //     });
                // })
                    .catch(error => console.log(error));
        })
        // .then(() => {
        //     this.setState({
        //         nextRace: this.state.racesDataList.find(option => new Date(option.date + "T" + option.time) > this.state.currentDateTime)
        //     });
        // })
        // .then(() => {
        //     this.setState({
        //         flagClass: this.findCountryCode("Name", this.state.nextRace.Circuit.Location.country)
        //     });
        // })
        .catch(error => console.log(error));

    }

    findCountryCode(title, country) {
        var code = CountryCodes.find(option => option[title] === country);
        return code ? code.Code.toLowerCase() : "gb";
    }

    render() {

        const displayCurrDateTime = new Date(this.state.currentDateTime);
        const displayRaceDateTime = this.state.nextRace ? new Date(this.state.nextRace.date + "T" + this.state.nextRace.time) : null;
        const left = this.state.nextRace ? displayRaceDateTime - displayCurrDateTime : null;
        if (left <= 0 && this.state.nextRace) {
            this.getNextRace();
        }
        const daysLeft = this.state.nextRace && Math.floor(left / (24 * 60 * 60 * 1000));
        const hoursLeft = this.state.nextRace && Math.floor((left % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
        const minutesLeft = this.state.nextRace && Math.floor((left % (60 * 60 * 1000)) / (60 * 1000));
        const secondsLeft = this.state.nextRace && Math.floor((left % (60 * 1000)) / (1000));

        return(
                <h3>
                    <div>
                        <div className="inline-table">
                            <p>Next Race: <span>{this.state.nextRace ? this.state.nextRace.raceName : /*"Loading..."*/""}</span></p>
                            <p>Time left: <span>{this.state.nextRace ? daysLeft + "d " + hoursLeft + "h " + minutesLeft + "m " + secondsLeft + "s" : /*"Loading..."*/""}</span></p>
                        </div>
                        <div className={"inline-table flag-pic title " + this.state.flagClass}></div>
                    </div>
                </h3>
        );
    }

}

export default Timer;