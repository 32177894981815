import React, { Suspense } from "react";
import FetchRaceResults from "./Funcs/fetchRaceResults";

class RaceResults extends React.Component {

    constructor() {
        super();
        this.state = {
            year: "",
            race: "",
            round: "",
            raceNumber: "",
            isLoadingRaceData: false,
            isLoadingQualiData: false,
            isLoadingWiki: false,
            isTableLoaded: false,
            raceData: [],
            qualiData: [],
            dataFileWiki: {},
            raceUrl: "",
            raceYear: "",
            raceName: "",
            seasonList: [],
            racesList: [],
            displayedRace: "",
            currentDateTime: Date.now(),
            displayRaceName: ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.getRacesList = this.getRacesList.bind(this);
        this.getSeasonsList = this.getSeasonsList.bind(this);
        this.getLastResultsSeasonAndRound = this.getLastResultsSeasonAndRound.bind(this);
    }

    componentDidMount() {

      this.props.location.param ? this.setState({
                                    year: this.props.location.param.pYear,
                                    raceNumber: this.props.location.param.pNumber
                                  }, () => {
                                    this.getSeasonsList();
                                    this.getRacesList();
                                  })
                                : this.getLastResultsSeasonAndRound();

    }

    handleChange(event) {
      const {name, value} = event.target;
      this.setState( {[name]: value}, () => this.getRacesList() )
    }

    getSeasonsList() {

      fetch("/f1seasons/getseasonlist")
        .then(respons => respons.clone().json())
        .then(dat => {
          this.setState({
            seasonList: dat.MRData.SeasonTable.Seasons
          });
        })
        .catch(error => console.log(error));

    }

    getRacesList() {

        let data = {
            year: this.state.year,
        };

        fetch("/f1raceresults/all", { 
          method: "post",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
        .then(respons => respons.clone().json())
        .then(dat => {
          this.setState({
              racesList: dat.MRData.RaceTable.Races
          });
        })
        .catch(error => console.log(error));

    }

    getLastResultsSeasonAndRound() {

      fetch("/f1timer/getlastresultablerace")
      .then(respons => respons.clone().json())
      .then(dat => { 
          this.setState({
            year: dat.MRData.RaceTable.Races[0].season,
            raceNumber: dat.MRData.RaceTable.Races[0].round
          }, () => { 
            this.getSeasonsList();
            this.getRacesList();
          });
      })
      .catch(error => console.log(error));
    }

    render() {

      const seasons = this.state.seasonList ? this.state.seasonList.map((option, index) => {
        return(<option key={index} value={option.season}>{option.season}</option>)
      }) : <option>{"Loading..."}</option>;

      const races = this.state.racesList ? this.state.racesList.map((option, index) => {
        return(<option key={index} value={option.round}>{option.round + " - " + option.raceName}</option>)
      }) : <option>{"Loading..."}</option>;

        return(
            <div className="main">
                <p className="description-text">Here you can find the statistics of any race and qualifying session. Please select the season and the race</p>
                <h2>Race results</h2>
                <div className="inline-table">
                <label>Year </label>
                <select 
                  className="drop-down-box"
                  name="year"
                  value={this.state.year}
                  onChange={this.handleChange}>
                    {seasons.reverse()}
                </select> 
                </div>
                <div className="inline-table">
                <label> Race </label>
                <select 
                  className="drop-down-box"
                  name="raceNumber"
                  value={this.state.raceNumber}
                  onChange={this.handleChange}
                  style={{width: "20em"}}>
                    {races}
                </select>
                </div>
                <br></br>
                <Suspense fallback={<div>Loading...</div>}>
                  <FetchRaceResults 
                    year={this.state.year}
                    raceNumber={this.state.raceNumber} />
                </Suspense>
                  <br></br>
                  <br></br>
                  <br></br>
            </div>
        );
    }
}

export default RaceResults;