import React from "react";
import { Link } from "react-router-dom";

function QualiTable(props) {

    return(
                <tr>
                    <td>{props.position}</td>
                    <td><div className={"flag-pic " + props.country1}></div>
                        {props.driverLink ? <Link to={{
                                                pathname: "/drivers",
                                                param: {
                                                    pDriver: props.driverLink
                                                }
                                            }}>{props.driver}</Link> : props.driver}</td>
                    <td><div className={"flag-pic " + props.country2}></div>{props.constructor}</td>
                    <td>{props.q1time}</td>
                    <td>{props.q2time}</td>
                    <td>{props.q3time}</td>
                </tr>
    )
}

export default QualiTable;