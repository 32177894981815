import React from "react";
import { Link } from "react-router-dom";

function WinsCountTable(props) {

    return(
        <tr>
            <td style={{width: "14em"}}><div className={"flag-pic " + props.country}></div>
                {props.driverLink ? <Link to={{
                                        pathname: "/drivers",
                                        param: {
                                            pDriver: props.driverLink
                                        }
                                    }}>{props.driver}</Link> : props.driver}</td>
            <td>{props.wins}</td>
        </tr>
    )
}

export default WinsCountTable;