import React from "react";
// import { Link } from "react-router-dom";

function NewsFeedItem(props) {

    return(
                // <div className="news-feed-item">
                //     <a href={props.link} target="_blank">
                //         <table>
                //             <tbody>
                //                 <tr>
                //                     <td className="inline-cells">
                //                         <div className="inline-cell">
                //                             <img src={props.mediaUrl} alt="preview" />
                //                         </div>
                //                         <div className="inline-cell">
                //                             <h3>{props.title}</h3>
                //                             <p>{props.date}</p>
                //                             {/* <p>{props.description}</p> */}
                //                         </div>
                //                     </td>
                //                 </tr>
                //                 <tr>
                //                     <td colSpan="2">
                //                         <p>{props.description}</p>
                //                     </td>
                //                 </tr>
                //             </tbody>
                //         </table>
                //     </a>
                // </div>

                <div className="news-feed-item">
                    <a href={props.link} target="_blank" rel="noopener noreferrer">
                        
                                <div className="inline-cells">
                                        <div className="inline-cell">
                                            <img src={props.mediaUrl} alt="preview" />
                                        </div>
                                        <div className="inline-cell">
                                            <h3>{props.title}</h3>
                                            <p>{props.date}</p>
                                            <p>{props.description}</p>
                                        </div>
                                    
                                </div>
                                {/* <div>
                                    <div>
                                        <p>{props.description}</p>
                                    </div>
                                </div> */}
                           
                    </a>
                </div>
    )
}

export default NewsFeedItem;