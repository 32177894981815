import React from "react";
import Timer from "./Timer";
import { Link } from "react-router-dom";

class Header extends React.Component {

  constructor() {
    super();
    this.state = {

    }
  }

  render () {
        return (
          <div className="header">
            <button onClick={this.props.showmenu}>
              <div className="sandwich-button">
                <span className={this.props.isopened ? "sandwich-top-opened" : "sandwich-top-closed"}></span>
                <span className={this.props.isopened ? "sandwich-mid-opened" : "sandwich-mid-closed"}></span>
                <span className={this.props.isopened ? "sandwich-bot-opened" : "sandwich-bot-closed"}></span>
              </div>
            </button>
            <Link to="/" onClick={this.props.isopened ? this.props.showmenu : undefined}><h2>Formula 1 statistics</h2></Link>
              <Timer />
          </div>
        );
      }

}

export default Header;