import React from "react";
import { Link } from "react-router-dom";
import flag_1 from "./pics/flag_1.png";
import helmet_1 from "./pics/helmet_1.png";
import racetrack_1 from "./pics/racetrack_1.png";
import cup_1 from "./pics/cup_1.png";
import racecar_1 from "./pics/racecar_1.png";
import podium_1 from "./pics/podium_1.png";
import stopwatch_1 from "./pics/stopwatch_favicon.png";

class Sidebar extends React.Component {

    render() {
        return(
            <div className={this.props.isopened ? "sidebar-opened" : "sidebar-closed"}>
              <ul className="sidebar-items">
                <li key="season">
                  <Link to="/" onClick={this.props.showmenu}>Seasons 1950-2019</Link>
                  <Link to="/" onClick={this.props.showmenu}><img src={stopwatch_1} alt="season"/></Link>
                </li>
                <li key="raceresults">
                  <Link to="/raceresults" onClick={this.props.showmenu}>Race Results</Link>
                  <Link to="/raceresults" onClick={this.props.showmenu}><img src={flag_1} alt="raceresults"/></Link>
                </li>
                <li key="drivers">
                  <Link to="/drivers" onClick={this.props.showmenu}>Drivers</Link>
                  <Link to="/drivers" onClick={this.props.showmenu}><img src={helmet_1} alt="drivers"/></Link>
                </li>
                <li key="constructors">
                  <Link to="/constructors" onClick={this.props.showmenu}>Constructors</Link>
                  <Link to="/constructors" onClick={this.props.showmenu}><img src={racecar_1} alt="constructors"/></Link>
                </li>
                <li key="circuits">
                  <Link to="/circuits" onClick={this.props.showmenu}>Circuits</Link>
                  <Link to="/circuits" onClick={this.props.showmenu}><img src={racetrack_1} alt="circuits"/></Link>
                </li>
                <li key="winnersbyseason" style={{marginTop: "5em"}}>
                  <Link to="/winnersbyseason" onClick={this.props.showmenu}>Winners by season</Link>
                  <Link to="/winnersbyseason" onClick={this.props.showmenu}><img src={cup_1} alt="winnersbyseason"/></Link>
                </li>
                <li key="driverbycircuit">
                  <Link to="/driverbycircuit" onClick={this.props.showmenu}>Driver by circuit</Link>
                  <Link to="/driverbycircuit" onClick={this.props.showmenu}><img src={podium_1} alt="driverbycircuit"/></Link>
                </li>
              </ul>
            </div>
        );
    }

}

export default Sidebar;