import React from "react";
import WikiSummaryTable from "../Tables/WikiSummaryTable";
import nFetch from "node-fetch";
import fetch_url_prefix from "./env_url";

let dataFileWiki = null; 
let promise = null;
let link = null;
let data = {};
const urlWiki = fetch_url_prefix + "/wikiextract/getsummary";

function getWikiSummary(props) {

    if (dataFileWiki && link === props.link) {

        return <WikiSummaryTable 
            picture={dataFileWiki.thumbnail && dataFileWiki.thumbnail.source}
            text={dataFileWiki.extract}
            wikilink={dataFileWiki.content_urls && dataFileWiki.content_urls.desktop.page} />

    } else {

        promise = new Promise(async (resolve) => {

            dataFileWiki = null;

            data = {
                link: props.link
            }

            await nFetch(urlWiki, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
                })
                .then(respons => respons.json())
                .then(dat => { 
                    dataFileWiki = dat ? dat : "Error";
                })
                .catch(error => console.log(error));

            link = props.link;
            resolve();
        });
    }

    throw promise;
}

export default getWikiSummary;