import React, { Suspense } from 'react';
import FetchWiki from "./Funcs/fetchWiki";
import FetchNews from "./Funcs/fetchNews";
import FetchSeasonStats from "./Funcs/fetchSeasonStats";

class RaceSchedule extends React.Component {

    constructor() {
        super();
        this.state = {
            year: "",
            seasonList: [],
            wikiLink: ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.getWikiLink = this.getWikiLink.bind(this);
    }

    componentDidMount() {

        fetch("/f1seasons/getseasonlist")
        .then(respons => respons.clone().json())
        .then(dat => {
          this.setState({
            seasonList: dat.MRData.SeasonTable.Seasons
          }, () => {
            this.props.location.param 
              ? 
                this.setState({
                  year: this.props.location.param.pYear
                }, () => this.getWikiLink())
              : 
                this.setState({
                  year: new Date().getFullYear()
                }, () => this.getWikiLink());
          })
        })
        .catch(error => console.log(error));

        // this.props.location.param 
        //   ? 
        //     this.setState({
        //       year: this.props.location.param.pYear
        //     }, () => this.getWikiLink())
        //   : 
        //     this.setState({
        //       year: new Date().getFullYear()
        //     }, () => this.getWikiLink());

        // this.getWikiLink()

    }

    handleChange(event) {
      this.setState({ year: event.target.value }, () => this.getWikiLink());
    }

    getWikiLink() {
      this.setState({
        wikiLink: this.state.seasonList.find(option => option.season === this.state.year.toString()).url
      })
    }

    render() {

      const seasons = this.state.seasonList && this.state.seasonList.map((option, index) => {
        return(<option key={index} value={option.season}>{option.season}</option>)
      });

      return(
        <div className="main">
            <p className="description-text">This is season statistics page. Here you can find race schedule, driver and constructor standings of any season. Also you can navigate to the results of any race or to driver and circuit statistics. Please select the season.</p>
            <select 
              className="drop-down-box"
              name="year"
              value={this.state.year}
              onChange={this.handleChange}>
                {seasons.reverse()}
            </select>
            <Suspense fallback={<div>Loading...</div>}>
              <FetchWiki link={this.state.wikiLink ? this.state.wikiLink : `https://en.wikipedia.org/wiki/${new Date().getFullYear()}_Formula_One_World_Championship`} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <FetchSeasonStats 
                year={this.state.year ? this.state.year : new Date().getFullYear()} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <FetchNews />
            </Suspense>
        </div>
      );
    }

}

export default RaceSchedule;