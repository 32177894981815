import React, { Suspense } from "react";
import FetchDrivers from "./Funcs/fetchDrivers";

class Drivers extends React.Component {

    constructor() {
        super();
        this.state = {
          dataFileDrivers: [],
          driverName: "",
          driverPosition: "",
          isLoadingDriver: false,
          isLoadingWiki: false,
          driversList: [],
          dataFileWiki: {},
          filteredListState: [],
          selectedDriver: {},
          selectedDriverID: "",
          isDriverListLoaded: false,
          isTableLoaded: false,
          driverWikiUrl: "",
          labelPositions: ""
        }
        this.getDriverInfo = this.getDriverInfo.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getLastResultsDriver = this.getLastResultsDriver.bind(this);
      }

      componentDidMount() {

        let url = "/f1getfulldata/getdriverslist";

        fetch(url)
        .then(respons => respons.clone().json())
        .then(dat => {
          this.setState({
            driversList: dat.MRData.DriverTable.Drivers,
            isDriverListLoaded: true
          });
        })
        .then(this.props.location.param ? this.setState({
          selectedDriverID: this.props.location.param.pDriver.driverId,
          driverName: this.props.location.param.pDriver.givenName + " " + this.props.location.param.pDriver.familyName,
          driverWikiUrl: this.props.location.param.pDriver.url,
          driverPosition: "all"
    })
  : this.getLastResultsDriver())
        .catch(error => console.log(error));

      }

      handleChange(event) {

        const {name, value} = event.target;

        this.setState({
              [name]: value,
              // selectedDriverID: this.state.driversList.find(option => option.givenName + " " + option.familyName === event.target.value) && this.state.driversList.find(option => option.givenName + " " + option.familyName === event.target.value).driverId
        });

        let driverFind = this.state.driversList.find(option => option.givenName + " " + option.familyName === event.target.value);
        if (driverFind)
          this.setState({
                selectedDriverID: driverFind.driverId,
                driverName: driverFind.givenName + " " + driverFind.familyName,
                driverWikiUrl: driverFind.url
          });

      }

      getLastResultsDriver() {

        fetch("/f1timer/getlastresultablerace")
        .then(respons => respons.clone().json())
        .then(dat => { 
              dat.MRData.RaceTable.Races[0].Results[0].Driver && this.setState({
                selectedDriverID: dat.MRData.RaceTable.Races[0].Results[0].Driver.driverId,
                driverName: dat.MRData.RaceTable.Races[0].Results[0].Driver.givenName + " " + dat.MRData.RaceTable.Races[0].Results[0].Driver.familyName,
                driverWikiUrl: dat.MRData.RaceTable.Races[0].Results[0].Driver.url
            });
        })
        .catch(error => console.log(error));
      }

      getDriverInfo(event) {

        this.setState({
          driverPosition: event ? event.target.value : "all"
        });

      }

        render () {

          const driverListFiltered = this.state.driversList ? this.state.driversList.map(option => {
              return(
                <option
                      key={option.driverId}
                      value={option.givenName + " " + option.familyName}>
                </option>
              );
          }) : "Loading...";

            return (
              <div className="main">
                <p className="description-text">Racedriver statistics is here. You can find the wins, the podiums or all race results. Please select the driver.</p>
                <h2>Drivers - Race Results</h2>
                <div className="inline-table">
                  <label>Driver </label>
                  <input 
                        className="test-text-box"
                        type="text" 
                        name="driverName" 
                        value={this.state.driverName} 
                        onChange={this.handleChange} 
                        list="driverlist">
                  </input>
                  <datalist id="driverlist" className="filtered-list">
                    {driverListFiltered}
                  </datalist>
                </div>
                <div className="inline-table">
                  <button value="1" onClick={this.getDriverInfo}>Wins</button>
                  <button value="2" onClick={this.getDriverInfo}>2nd places</button>
                  <button value="3" onClick={this.getDriverInfo}>3rd places</button>
                  <button value="all" onClick={this.getDriverInfo}>all results</button>
                </div>
                <Suspense fallback={<div>Loading...</div>}>
                  <FetchDrivers
                    // driverName={this.state.driverName}
                    position={this.state.driverPosition}
                    driverID={this.state.selectedDriverID}
                    // driverWikiUrl={this.state.driverWikiUrl} 
                    />
                </Suspense>
              </div>
            );
          }
}

export default Drivers;
