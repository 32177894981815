import React, { Suspense } from "react";
import FetchConstructors from "./Funcs/fetchConstructors";

class Constructors extends React.Component {

    constructor() {
        super();
        this.state = {
            dataFileConstructors: [],
            constructor: "",
            circuitID: "",
            year: "",
            selectedConstructor: {},
            isLoadingConstructor: false,
            constructorsList: [],
            isConstructorsListLoaded: false,
            isTableLoaded: false,
            selectedConstructorID: "",
            constructorName: "",
            constructorWikiUrl: "",
            constructorPosition: ""
          }
          this.getConstructorsInfo = this.getConstructorsInfo.bind(this);
          this.handleChange = this.handleChange.bind(this);
          this.getLastResultsConstructor = this.getLastResultsConstructor.bind(this);
        }
      
    handleChange(event) {
        const {name, value} = event.target;
        this.setState({
          [name]: value,
          selectedConstructor: this.state.constructorsList.find(option => option.name === event.target.value)
        })

        let constructorFind = this.state.constructorsList.find(option => option.name === event.target.value);
        if (constructorFind)
          this.setState({
                // [name]: value,
                selectedConstructorID: constructorFind.constructorId,
                constructorName: constructorFind.name,
                constructorWikiUrl: constructorFind.url
          });
    }

    componentDidMount() {

      let url = "/f1getfulldata/getconstructorslist";

        fetch(url)
        .then(respons => respons.clone().json())
        .then(dat => {
          this.setState({
            constructorsList: dat.MRData.ConstructorTable.Constructors,
            isConstructorsListLoaded: true
          });
        })
        .catch(error => console.log(error));

      this.getLastResultsConstructor();

    }

    getLastResultsConstructor() {

      fetch("/f1timer/getlastresultablerace")
      .then(respons => respons.clone().json())
      .then(dat => { 
            dat.MRData.RaceTable.Races[0].Results[0].Constructor && this.setState({
              selectedConstructorID: dat.MRData.RaceTable.Races[0].Results[0].Constructor.constructorId,
              constructorName: dat.MRData.RaceTable.Races[0].Results[0].Constructor.name,
              constructorWikiUrl: dat.MRData.RaceTable.Races[0].Results[0].Constructor.url
          });
      })
      .catch(error => console.log(error));
    }

    getConstructorsInfo(event) {

      this.setState({
        constructorPosition: event && event.target.value
      });
    }

    render() {

        const constructorsListFiltered = this.state.constructorsList ? this.state.constructorsList.map(option => {
          return(
            <option
                  key={option.constructorId}
                  value={option.name}>
            </option>
          );
        }) : "Loading...";

        return(
            <div className="main">
                    <p className="description-text">Here you can find the wins or the podiums of any constructor. Please select the constructor.</p>
                    <h2>Constructors - Winners and Podiums</h2>
                    <label>Constructor </label>
                    <input 
                          className="test-text-box"
                          type="text" 
                          name="constructorName" 
                          value={this.state.constructorName} 
                          onChange={this.handleChange} 
                          list="constructorslist">
                    </input>
                    <datalist id="constructorslist" className="filtered-list">
                      {constructorsListFiltered}
                    </datalist>
                    <div className="inline-table">
                    <button value="1" onClick={this.getConstructorsInfo}>1st</button>
                    <button value="2" onClick={this.getConstructorsInfo}>2nd</button>
                    <button value="3" onClick={this.getConstructorsInfo}>3rd</button>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Suspense fallback={<div>Loading...</div>}>
                      <FetchConstructors 
                        // driver={this.state.driver}
                        constructorName={this.state.constructorName}
                        position={this.state.constructorPosition}
                        constructorID={this.state.selectedConstructorID}
                        constructorWikiUrl={this.state.constructorWikiUrl} />
                    </Suspense>
                    <br></br>
                    <br></br>
                    <br></br>
              </div>
        )
    }
}

export default Constructors;