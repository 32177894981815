import React, { Suspense } from "react";
import nFetch from "node-fetch";
import CountryCodes from "../../country_codes.json";
import fetch_url_prefix from "./env_url";
import FetchWiki from "./fetchWiki";
import getLastResultableRaceWinner from "./getLastResultableRace";
import ConstructorWinnerTable from "../Tables/ConstructorWinnerTable";

let dataFileConstructors = null;
let promiseLastResultableRace = null;
let promiseConstructor = null;
let lastRaceData = null;
let constructorID = null;
let position = null;
let constructorWikiUrl = null;
let constructorName = null;
const url = fetch_url_prefix + "/f1constructors";

function findCountryCode(title, country) {
    var code = CountryCodes.find(option => option[title] === country);
    return code ? code.Code.toLowerCase() : "gb";
}

function fetchConstructors(props) {

    // console.log(driverID + " / " + props.driverID + " ----- " + position + " / " + props.position);
    // console.log(dataFileDrivers && driverID === props.driverID && position === props.position);
    // console.log(driverName);

    if (dataFileConstructors && constructorID === props.constructorID && position === props.position) {

        // if (positionFetched === "1") { label = "Wins of " + driverName; }
        // else if (positionFetched === "2") { label = "2nd places of " + driverName; }
        // else if (positionFetched === "3") { label = "3rd places of " + driverName; }
        // else { label = "All races of " + driverName; }
        
        const wins = dataFileConstructors.map((option, index) => {
            return(
                <ConstructorWinnerTable
                            key={index}
                            number={index + 1}
                            season={option.season}
                            round={option.round}
                            raceName={option.raceName}
                            country1={findCountryCode("Name", option.Circuit.Location.country)}
                            circuitName={option.Circuit.circuitName}
                            raceDate={new Date(option.date).toDateString().substring(4, 10)}
                            country2={findCountryCode("Nationality", option.Results[0].Driver.nationality)}
                            driver={option.Results[0].Driver.givenName + " " + option.Results[0].Driver.familyName}
                            grid={option.Results[0].grid}
                            position={option.Results[0].position}
                            points={option.Results[0].points}
                            yearLink={option.season}
                            raceNumberLink={option.round}
                            circuitLink={option.Circuit}
                            driverLink={option.Results[0].Driver} />);
        });

        return  <div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <FetchWiki link={constructorWikiUrl} />
                    </Suspense>
                    <label className="results-table-opened title">
                          {constructorName + " - Number of races total: " + dataFileConstructors.length}
                    </label>
                    <br></br>
                    <br></br><table className="results-table-opened">
                       <tbody>
                          <tr>
                              <td>N</td>
                              <td>Season</td>
                              <td className="td-collapse-1000">Date</td>
                              <td>Round</td>
                              <td>Race Name</td>
                              <td>Circuit</td>
                              <td>Driver</td>
                              <td>Grid</td>
                              <td>Position</td>
                              <td>Points</td>
                          </tr>
                          {wins}
                        </tbody>
                      </table>
                </div>

    } else {

        // console.log(driverID + " / " + props.driverID + " ----- " + position + " / " + props.position);

        promiseLastResultableRace = new Promise(async (resolve) => {

            lastRaceData = await getLastResultableRaceWinner();

            let data = {
                position: props.position ? props.position : "1",
                constructor: props.constructorID ? props.constructorID : lastRaceData.Results[0].Constructor.constructorId
            };

            constructorName = props ? props.constructorName : lastRaceData.Results[0].Constructor.name;
            constructorWikiUrl = props ? props.constructorWikiUrl : lastRaceData.Results[0].Constructor.url;

            resolve(data);
        });

        promiseConstructor = new Promise(async (resolve) => {

            let param = await promiseLastResultableRace;

            let options = { 
                method: "post",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(param)
            }

            // console.log(data);
            // console.log(driverName);

            dataFileConstructors = null;
      
            await nFetch(url, options)
              .then(respons => respons.json())
              .then(dat => {
                    dataFileConstructors = dat.MRData.RaceTable.Races ? dat.MRData.RaceTable.Races : [];
                    constructorID = props.constructorID;
                    position = props.position;
              })
              .catch(error => console.log(error));

            // console.log(driverName);
    
            resolve();
        });
        
        // driverID = props.driverID;
        // position = props.position;
        // driverName = props.driverName
    }

    throw promiseConstructor;

    // promiseAll = Promise.all([promiseDriver, promiseLastResultableRace]);

    // throw promiseAll;

}

export default fetchConstructors;

