// # for react app // REACT_APP_FETCH_URL=
// # for using node fetch // REACT_APP_FETCH_URL=http://localhost:9000
// # for production // REACT_APP_FETCH_URL=https://f1reactor.com

//for node-fetch only absolute urls are allowed
// const fetch_url_prefix = "";
// const fetch_url_prefix = "http://localhost:9000";
// const fetch_url_prefix = "https://localhost:9000";
const fetch_url_prefix = "https://f1reactor.com";

// //trying to detect the environment
// //relative urls for the browser
// let fetch_url_prefix = "";
// //absolute urls for node
// if (typeof module === "object" && typeof module.exports === "object") {
//     fetch_url_prefix = "http://localhost:9000";
// }

export default fetch_url_prefix;